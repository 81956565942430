import { mapIndexed } from '@betalpha/stdlib/base';
import { flow, split, join, first, isEqual, prop } from 'lodash/fp';
import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { useGetEvaluationReportRiskSummaryQuery, useGetRiskDetailQuery } from '@/infra/api/funds';

import EmptyContent from '@/components/emptyContent';
import style from '../index.module.less';
import { getMatchDegree, riskLevel, CHART_COLOR } from '../constants';

interface StyleDynamicAlertProps {
  fundId: string;
}
export default React.memo(({ fundId }: StyleDynamicAlertProps) => {
  const { data: riskDetail } = useGetRiskDetailQuery(fundId, { skip: !fundId });
  const { data: evaluationReportRiskSummary } = useGetEvaluationReportRiskSummaryQuery(fundId, { skip: !fundId });

  const { styleRisk } = evaluationReportRiskSummary || {};

  const fundStyle = useMemo(() => {
    const fundStyleMap = {
      g: getMessage('fundStyle_G'),
      v: getMessage('fundStyle_V'),
      m: getMessage('fundStyle_M')
    };
    return flow(prop('fundStyle'), split('MyTag_rating23_'), join(''), first, (val: string) => prop(val)(fundStyleMap))(
      styleRisk
    );
  }, [styleRisk]);

  const { styleRiskDetail } = riskDetail || {};

  const { curFundStyleValue, historyFundStyleValue } = styleRiskDetail ?? {
    curFundStyleValue: NaN,
    historyFundStyleValue: NaN
  };

  const compareHistory = useMemo(() => {
    if (isEqual(curFundStyleValue)(historyFundStyleValue)) {
      return getMessage('fit');
    }
    if (curFundStyleValue <= 0.3 && historyFundStyleValue <= 0.3) {
      return getMessage('fit');
    }
    if (
      curFundStyleValue > 0.3 &&
      curFundStyleValue < 0.7 &&
      historyFundStyleValue > 0.3 &&
      historyFundStyleValue < 0.7
    ) {
      return getMessage('fit');
    }
    if (
      curFundStyleValue >= 0.7 &&
      curFundStyleValue <= 1 &&
      historyFundStyleValue >= 0.7 &&
      historyFundStyleValue <= 1
    ) {
      return getMessage('fit');
    }
    return getMessage('atypism');
  }, [curFundStyleValue, historyFundStyleValue]);

  const getLeftVal = (value: number | undefined) => `${(value ?? -9999) * 100}%`;

  return (
    <div className={style.StyleDynamicAlertWrap}>
      {styleRisk ? (
        <>
          <div className={style.Text}>
            {getMessage('styleDynamicAlertSummary', {
              fundStyle,
              compareHistory,
              matchDegree: getMatchDegree(styleRisk?.riskLevel)
            })}
          </div>
          <div className={style.ChartWrap}>
            <div className={style.Chart}>
              {mapIndexed((key: string, idx: number) => (
                <div key={idx} style={{ backgroundColor: CHART_COLOR[idx] }}>
                  {getMessage(key)}
                </div>
              ))(riskLevel)}
            </div>
            <div className={style.Arrows}>
              <div style={{ left: getLeftVal(styleRiskDetail?.curFundStyleValue) }}>{getMessage('current')}</div>
              <div style={{ left: getLeftVal(styleRiskDetail?.historyFundStyleValue) }}>{getMessage('History')}</div>
            </div>
          </div>
        </>
      ) : (
        <EmptyContent message={getMessage('noData')} />
      )}
    </div>
  );
});
