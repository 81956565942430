import React from 'react';
import { isEmpty, map } from 'lodash/fp';
import style from './index.module.less';

interface TagListProps {
  title: string;
  tags?: string[];
}

const TagList = React.memo<TagListProps>(({ title, tags }) => (
  <div className={style.TagListWrap}>
    <div className={style.Title}>{title}</div>
    <div className={style.TagWrap}>
      {map((industry: string) => (
        <div key={industry} className={style.Tag}>
          {industry || '--'}
        </div>
      ))(!isEmpty(tags) ? tags : ['--'])}
    </div>
  </div>
));

export default TagList;
