import React from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import CardLayout from '@/components/cardLayout';
import { useGetEvaluationReportInfoQuery } from '@/infra/api/funds';
import { EvaluationReportInfoBody } from '@/interface/fundDetail';
import PerformanceTrendChart from './performanceTrendChart';

export default React.memo(() => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetEvaluationReportInfoQuery(fundId || '', { skip: !fundId });

  return (
    <CardLayout title={getMessage('performanceTrend')} loading={isFetching}>
      <PerformanceTrendChart fundEvaluationReportInfo={data as EvaluationReportInfoBody} showDate />
    </CardLayout>
  );
});
