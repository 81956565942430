import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@betalpha/router';
import { replace } from 'lodash/fp';
import { getMessage } from '@betalpha/intl';
import { useTitle } from 'ahooks';
import { allFundsMapSelector } from '@/selectors/funds';
import Page from '@/components/page';
import ActionBar from '@/components/action-bar';
import { FUND_EVALUATION } from '@/const';
import NoticeBar from '@/components/notice-bar';
import Share from '@/components/share';
import { useSearchPopup } from '@/components/action-bar/hooks';
import PerformanceTrend from './components/performanceTrend';
import BasicInfo from './components/basic-info';
import Ability from './components/ability';
import Risk from './components/risk';
import FundManager from './components/fund-manager';

export default React.memo(() => {
  useTitle(getMessage('fundEvaluation2'), { restoreOnUnmount: true });
  const { fundId } = useParams();
  const fundMap = useSelector(allFundsMapSelector);
  const fund = useMemo(() => fundMap?.[fundId || ''], [fundMap, fundId]);
  const searchNode = useSearchPopup();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [fundId]);

  return (
    <Page
      needFund
      type="FUND_EVALUATION"
      actionBar={
        <ActionBar
          title={fund?.fundName}
          rootPath="/"
          subTitle={fund?.fundId ? `(${replace('_OF')('')(fund?.fundId)})` : ''}
          extra={
            <>
              {searchNode}
              {fund ? <Share PDFType="FUND_EVALUATION" /> : null}
            </>
          }
        />
      }
    >
      {fund ? (
        <>
          <NoticeBar type={FUND_EVALUATION} />
          <BasicInfo />
          <PerformanceTrend />
          <Ability />
          <Risk />
          <FundManager />
        </>
      ) : null}
    </Page>
  );
});
