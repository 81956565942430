import React, { ReactNode, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { getMessage } from '@betalpha/intl';
import cn from 'classnames';
import style from './index.module.less';

interface CollapseProps {
  title: ReactNode;
  children: ReactNode;
  className?: string;
}
const Collapse = React.memo<CollapseProps>(({ title, children, className }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <div className={cn(style.Collapse, className)}>
      <div className={style.TitleWrap}>
        <div className={style.Title}>{title}</div>
        <div
          className={style.CollapseBtn}
          onClick={(e) => {
            e.stopPropagation();
            setIsCollapsed(!isCollapsed);
          }}
        >
          {isCollapsed ? (
            <>
              {getMessage('expandPanel')} <DownOutlined />
            </>
          ) : (
            <>
              {getMessage('collapsePanel')} <UpOutlined />
            </>
          )}
        </div>
      </div>
      {!isCollapsed && <div className={style.ChildrenWrap}>{children}</div>}
    </div>
  );
});

export default Collapse;
