import { getHTMLMessage, getMessage } from '@betalpha/intl';
import { toFixedNumber } from '@betalpha/stdlib/lib/numberFormatter';
import { EChartsOption } from 'echarts';
import { map, zip, join, prop, identity, isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { IndustryRiskDetailParam } from '@/interface/fundDetail';
import { useGetEvaluationReportRiskSummaryQuery, useGetRiskDetailQuery } from '@/infra/api/funds';
import TagList from '@/components/tag-list';
import EmptyContent from '@/components/emptyContent';
import BaseEcharts from '../../../../../components/baseEcharts';
import { getProsperity, getCostPerformance, getRiskLevel } from '../constants';
import style from '../index.module.less';

export default React.memo(({ fundId }: { fundId: string }) => {
  const { data: riskDetail } = useGetRiskDetailQuery(fundId, { skip: !fundId });
  const { industryRiskDetails } = riskDetail || {};

  const { valueA, valueB, industries } = useMemo(
    () => ({
      valueA: map((item: IndustryRiskDetailParam) => item?.industryProsperity)(industryRiskDetails),
      valueB: map((item: IndustryRiskDetailParam) => item?.industryCongestion)(industryRiskDetails),
      industries: map((item: IndustryRiskDetailParam) => item?.industryName)(industryRiskDetails)
    }),
    [industryRiskDetails]
  );

  const { data: evaluationReportRiskSummary } = useGetEvaluationReportRiskSummaryQuery(fundId, { skip: !fundId });

  const { industryRisk } = evaluationReportRiskSummary || {};

  const options = useMemo<EChartsOption>(
    () => ({
      grid: { containLabel: true, top: 20, bottom: 0, left: 0, right: 30 },
      xAxis: { type: 'value', show: false, min: 0, max: 100 },
      legend: { itemGap: 20, bottom: 0 },
      yAxis: {
        type: 'category',
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          formatter: identity,
          width: 60,
          fontWeight: 400,
          overflow: 'break'
        }
      },
      series: isEmpty(industries)
        ? []
        : [
            {
              name: getMessage('prosperityScore'),
              type: 'bar',
              barWidth: 17,
              silent: true,
              label: {
                position: 'right',
                color: '#333',
                fontWeight: 'normal',
                formatter: (params) => {
                  const value = prop('value.0')(params);
                  return toFixedNumber(2)(value);
                }
              },
              data: zip(valueA)(industries) as [number, string][]
            },
            {
              name: getMessage('crowdingScore'),
              type: 'bar',
              barWidth: 17,
              silent: true,
              label: {
                position: 'right',
                color: '#333',
                fontWeight: 'normal',
                formatter: (params) => {
                  const value = prop('value.0')(params);
                  return toFixedNumber(2)(value);
                }
              },
              data: zip(valueB)(industries) as [number, string][]
            }
          ]
    }),
    [industries, valueA, valueB]
  );
  return (
    <div className={style.RecentSituationOfHeavyWarehouseIndustryWrap}>
      {!isEmpty(industryRiskDetails) ? (
        <>
          <div className={style.SubTitleWrap}>
            <TagList title={getMessage('top3HoldingPositionsIndustries')} tags={industries} />
          </div>
          <div className={style.Text}>
            {getHTMLMessage('heavyWarehouseIndustrySummaryForMobile', {
              industries: join('、')(industries),
              prosperity: getProsperity(industryRisk?.industryProsperity),
              crowdDegree: getProsperity(industryRisk?.industryCongestion),
              costPerformance: getCostPerformance(industryRisk?.industryProsperity, industryRisk?.industryCongestion),
              risk: `${getMessage('risk')}${getRiskLevel(industryRisk?.riskLevel)}`
            })}
          </div>
          <div className={style.ChartWrap}>
            <BaseEcharts options={options} height={224} />
          </div>
        </>
      ) : (
        <EmptyContent message={getMessage('noData')} />
      )}
    </div>
  );
});
