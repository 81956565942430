// todo update 先简单从index.tsx文件拆分出来为了其他地方复用 以后再重构
import React, { FC, ReactNode } from 'react';
import { getMessage } from '@betalpha/intl';
import { isEmpty, map } from 'lodash/fp';
import { mapIndexed } from '@betalpha/stdlib/base';
import EvaluationDetailTrigger from '@/components/evaluation-detail-trigger';
import EmptyContent from '@/components/emptyContent';

import style from './index.module.less';

type Column = {
  dataIndex: string;
  title: string;
  render?: (value: any, record: any) => ReactNode;
};

type DataSourceItem = {
  [propsName: string]: any;
};

const Row: FC<{ data: DataSourceItem; columns: Column[] }> = ({ data, columns }) => (
  <div className={style.TableData}>
    {columns?.map((column) => (
      <div key={column?.dataIndex}>
        {column?.render ? column?.render(data?.[column?.dataIndex], data) : data?.[column?.dataIndex]}
      </div>
    ))}
  </div>
);

const YieldTable: FC<{
  dataSource: DataSourceItem[];
  columns: Column[];
}> = ({ dataSource, columns }) => {
  return (
    <div className={style.TableWrap}>
      <div className={style.TableHeader}>
        {map((item: Column) => <div key={item?.dataIndex}>{item?.title}</div>)(columns)}
      </div>
      <div className={style.Divider} />
      {isEmpty(dataSource) ? (
        <EmptyContent message={getMessage('noData')} />
      ) : (
        <EvaluationDetailTrigger initalizeHeight={145}>
          {mapIndexed((data: DataSourceItem, idx: number) => {
            return <Row data={data} columns={columns} key={idx} />;
          })(dataSource)}
        </EvaluationDetailTrigger>
      )}
    </div>
  );
};

export default React.memo(YieldTable);
