import React, { useCallback } from 'react';
import { getMessage } from '@betalpha/intl';
import { useNavigate } from '@betalpha/router';
import { useGetHotFundsQuery } from '@/infra/api/funds';
import CardLayout from '@/components/cardLayout';
import HotFund from './hot';
import style from './index.module.less';

export default React.memo(() => {
  const { data, isFetching } = useGetHotFundsQuery();

  const navigateTo = useNavigate();

  const handleFundClick = useCallback(
    (fundId: string) => {
      navigateTo(`${fundId}`);
    },
    [navigateTo]
  );
  return (
    <CardLayout
      className={style.PopularFundWrap}
      title={<h1>{getMessage('popularFiveStarFund')}</h1>}
      loading={isFetching}
    >
      <HotFund data={data} onClick={handleFundClick} />
    </CardLayout>
  );
});
