import React from 'react';

import { getMessage } from '@betalpha/intl';
import { useTitle } from 'ahooks';
import Page from '@/components/page';
import ActionBar from '@/components/action-bar';
import NoticeBar from '@/components/notice-bar';
import { FUND_EVALUATION } from '@/const';
import FundSearch from '@/components/fundSearch';
import style from './index.module.less';
import RecentPerformance from './components/recentPerformance';
import PopularFund from './components/popularFund';

interface HomeViewProps {
  fundId?: string;
}

export default React.memo<HomeViewProps>(() => {
  useTitle(getMessage('fundEvaluation2'), { restoreOnUnmount: true });
  return (
    <Page actionBar={<ActionBar back={false} rootPath="/" title={<FundSearch />} />} className={style.HomeViewWrap}>
      <NoticeBar type={FUND_EVALUATION} />
      <RecentPerformance />
      <PopularFund />
    </Page>
  );
});
