import { getMessage } from '@betalpha/intl';
import cumulativeReturns from '@betalpha/stdlib/lib/quant/cumulativeReturns';
import { EChartsOption } from 'echarts';
import { map, set, toLower, prop, isEmpty, orderBy, size } from 'lodash/fp';
import React, { useMemo } from 'react';
import { mapIndexed } from '@betalpha/stdlib/base';
import classNames from 'classnames';
import { formatPercentage } from '@/util/numberFormatter';
import { MarketRankDetailsParam } from '@/interface/fundDetail';
import style from '../index.module.less';
import BaseEcharts from '../../../../../components/baseEcharts';
import { getDateOfQuarter, getTextColor, getTooltipColorInfo } from './contants';
import { useCalculatePosition } from './hooks';

export default React.memo(
  ({
    name,
    type,
    markAreaColor,
    className,
    fundEvaluationReportInfo,
    marketCapabilityDetail
  }: {
    name: string;
    type: 'RISE' | 'FALL';
    markAreaColor: string;
    className?: string;
    fundEvaluationReportInfo: any;
    marketCapabilityDetail: any;
  }) => {
    const returns = prop('returns')(fundEvaluationReportInfo);
    const { fundReturns: fundDailyReturns, dates, benchmarkReturns: benchmarkDailyReturns } = returns || {};

    const { marketRisingRankingDetails, marketFallRankingDetails, sameTypeFundCount } = marketCapabilityDetail || {};

    const riskRankingDetails = useMemo(() => {
      if (type === 'RISE') {
        if (!marketRisingRankingDetails) return marketRisingRankingDetails;
        return orderBy<MarketRankDetailsParam>('startDate', 'asc')(marketRisingRankingDetails);
      }
      if (!marketFallRankingDetails) return marketFallRankingDetails;
      return orderBy<MarketRankDetailsParam>('startDate', 'asc')(marketFallRankingDetails);
    }, [marketFallRankingDetails, marketRisingRankingDetails, type]);
    const markAreaData = useMemo(() => {
      if (!riskRankingDetails) return riskRankingDetails;
      return map((item: MarketRankDetailsParam) => [
        {
          xAxis: item?.startDate
        },
        {
          xAxis: item?.endDate
        }
      ])(riskRankingDetails);
    }, [riskRankingDetails]);

    const options = useMemo<EChartsOption>(
      () => ({
        graphic: {
          type: 'text',
          right: 0,
          bottom: 0
        },
        grid: {
          left: 60,
          top: 20,
          bottom: 35,
          right: 30
        },
        legend: {
          itemHeight: 4,
          icon: 'path://M30 0 L200 0 Q 230 30 200 60 L30 60 Q 0 30 30 0 Z'
        },
        xAxis: {
          zlevel: 1,
          data: dates,
          axisLabel: {
            color: '#666666',
            fontWeight: 'normal',
            interval: (index, number) => {
              if (index === 0 || index === size(dates) - 1) {
                return number;
              }
              return false;
            },
            fontFamily: 'DINAlternate',
            fontSize: 10
          },
          axisLine: { onZero: true }
        },
        yAxis: {
          axisLabel: {
            color: '#666666',
            fontWeight: 'normal'
          }
        },
        series: [
          {
            name: getMessage('theFund'),
            type: 'line',
            data: cumulativeReturns(set(0, 0)(fundDailyReturns), true),
            markArea: {
              silent: true,
              itemStyle: { color: markAreaColor },
              data: markAreaData as any
            }
          },
          {
            name: getMessage('csIndex800'),
            type: 'line',
            data: cumulativeReturns(set(0, 0)(benchmarkDailyReturns), true)
          }
        ]
      }),
      [dates, fundDailyReturns, markAreaColor, markAreaData, benchmarkDailyReturns]
    );

    const tooltipColorInfo = useMemo(() => getTooltipColorInfo(type), [type]);
    const { onChartReady, tooltipPositionInfo, triangleNode } = useCalculatePosition(
      markAreaColor,
      tooltipColorInfo.background
    );
    return (
      <div className={classNames(className, style.TableChartWrap)}>
        <div className={style.TableTitle}>
          {name}（
          {getMessage('highestIncreaseThreeQuarters', {
            riseOrFall: getMessage(toLower(type))
          })}
          ）
        </div>
        <div className={style.TableWrap}>
          <div className={style.TableCol}>
            <div className={style.Col}>{getMessage('periodIncome')}：</div>
            <div className={style.Col}>{getMessage('excessIncome')}：</div>
            <div className={style.Col}>{getMessage('similarRankings')}：</div>
          </div>
          {!isEmpty(dates) && tooltipPositionInfo && (
            <div className={style.DataColWrap}>
              {mapIndexed((item: MarketRankDetailsParam, index: number) => {
                return (
                  <div
                    className={classNames(style.TableCol, style.DataCol)}
                    style={{
                      left: tooltipPositionInfo[index]?.left,
                      backgroundColor: tooltipColorInfo.background
                    }}
                    key={item.startDate}
                  >
                    <div
                      className={style.ColTitle}
                      style={{
                        backgroundColor: tooltipColorInfo.title,
                        borderBottom: `1px solid ${tooltipColorInfo.border}`
                      }}
                    >
                      {getDateOfQuarter(item.endDate)}
                    </div>
                    <div className={style.ColContent}>
                      <div className={style.Col} style={{ color: getTextColor(item.sectionReturn) }}>
                        {formatPercentage(item?.sectionReturn)}
                      </div>
                      <div className={style.Col} style={{ color: getTextColor(item.excessReturn) }}>
                        {formatPercentage(item?.excessReturn)}
                      </div>
                      <div className={style.Col} style={{ color: '#999999', fontWeight: 500 }}>
                        <span style={{ fontWeight: 600, color: '#000' }}>{item?.sameTypeRank}</span>/{sameTypeFundCount}
                      </div>
                    </div>
                  </div>
                );
              })(riskRankingDetails)}
              {triangleNode}
            </div>
          )}
        </div>
        <div className={style.ChartWrap}>
          {!isEmpty(dates) && <BaseEcharts options={options} onChartReady={onChartReady} height={200} />}
        </div>
      </div>
    );
  }
);
