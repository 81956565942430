import React, { useMemo } from 'react';

import { compact, flow, isEmpty, isNumber, map, pick, prop, round } from 'lodash/fp';
import { normalize, mapIndexed } from '@betalpha/stdlib/base';
import { getMessage } from '@betalpha/intl';
import { fixedNumber, formatPercentage } from '@/util/numberFormatter';
import { ManagerEvaluationBody } from '@/interface/fundDetail';
import ColorNumber from '@/components/colorNumber';
import { useGetEvaluationReportAbilitySummaryQuery } from '@/infra/api/funds';
import { managerFactorRatingsOrder, factorRatingsOrder } from '../../constants';
import { useGetDefaultRadarOptions } from '../../hooks';
import BaseEcharts from '../../../../components/baseEcharts';
import style from './index.module.less';

export default React.memo(
  ({
    fundManagerInfo,
    fundId
  }: {
    fundManagerInfo: ManagerEvaluationBody['fundManagerInfos'][number];
    fundId: string;
  }) => {
    const data = useMemo(
      () => [
        { label: getMessage('commencementDateToNow'), value: prop('duration')(fundManagerInfo) },
        {
          label: getMessage('representativeFund'),
          value: prop('representativeFundName')(fundManagerInfo)
        },
        {
          label: getMessage('tenureReturn'),
          value: <ColorNumber formatter={formatPercentage} value={prop('tenureReturn')(fundManagerInfo)} />
        },
        {
          label: getMessage('manageScale'),
          value: fixedNumber(prop('manageScale')(fundManagerInfo))
        }
      ],
      [fundManagerInfo]
    );

    const { data: evaluationReportAbilitySummary } = useGetEvaluationReportAbilitySummaryQuery(fundId, {
      skip: !fundId
    });

    const sortedFactorRatings = useMemo(() => {
      const managerFactorRatings = prop('managerFactorRatings')(fundManagerInfo);
      const factorRatingsCodeMap = flow(prop('factorRatings'), (factorRatings) =>
        normalize(factorRatings, 'factorCode')
      )(evaluationReportAbilitySummary);

      return flow(
        (val) => normalize(val, 'factorCode'),
        (mapper) =>
          mapIndexed((factorCode: string, idx: number) => ({
            ...pick(['factorCode', 'factorName', 'sameTypeFundFactorRating'])(
              prop(`${factorCode}`)(factorRatingsCodeMap)
            ),
            ...(prop(factorCode)(mapper) || prop(managerFactorRatingsOrder[idx])(mapper) || {})
          }))(factorRatingsOrder)
      )(managerFactorRatings);
    }, [evaluationReportAbilitySummary, fundManagerInfo]);

    const indicatorsNameMap = useMemo(() => normalize(sortedFactorRatings, 'factorName'), [sortedFactorRatings]);
    const radarChartData = useMemo(() => {
      return [
        {
          name: fundManagerInfo?.name,
          value: map((item: Record<string, any>) => item?.rating)(sortedFactorRatings)
        },
        {
          name: getMessage('averageOfTheSameType'),
          value: map((item: Record<string, any>) => item?.sameTypeFundFactorRating)(sortedFactorRatings)
        }
      ];
    }, [fundManagerInfo, sortedFactorRatings]);

    const isEmptyRatingValues = flow(
      map((item: Record<string, any>) => {
        const rating = item?.rating;
        if (isNumber(rating)) return true;
        return null;
      }),
      compact,
      isEmpty
    )(sortedFactorRatings);

    const options = useGetDefaultRadarOptions(
      {
        series: [
          {
            z: 2,
            data: [radarChartData[0]]
          },
          {
            z: 1,
            data: [radarChartData[1]]
          }
        ],
        graphic: [
          ...(isEmptyRatingValues
            ? [
                {
                  type: 'text',
                  left: 'center',
                  top: '60%',
                  zlevel: 9,
                  style: {
                    text: getMessage('insufficientData'),
                    fill: '#222222',
                    fontSize: 14
                  }
                }
              ]
            : [])
        ],
        radar: {
          indicator: map((item: any) => ({ name: item.factorName, max: 5 }))(sortedFactorRatings),
          radius: 55,
          axisName: {
            fontSize: 12,
            formatter: (name) => {
              const ratingNum = flow(prop(`${name}.rating`), round)(indicatorsNameMap) || 0;
              let res = `${name}\n`;
              const starStr = `{star|}`;
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < ratingNum; index++) {
                res += starStr;
                if (index < ratingNum - 1) res += ' ';
              }
              return res;
            }
          }
        }
      },
      10
    );

    return (
      <div className={style.ManagerInfoWrap}>
        <div className={style.ManagerInfo}>
          <div className={style.ManagerName}>{prop('name')(fundManagerInfo)}</div>
          <div className={style.TableWrap}>
            {map(({ label, value }) => {
              return (
                <div className={style.TableRow} key={value}>
                  <div className={style.Label}>{label}</div>
                  <div className={style.Value}>{value}</div>
                </div>
              );
            })(data)}
          </div>
        </div>
        <div className={style.Divider} />
        <div className={style.ChartWrap}>
          <BaseEcharts options={options} height={230} notMerge />
        </div>
      </div>
    );
  }
);
