import React, { useCallback, useMemo, useRef } from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { mapIndexed, normalize } from '@betalpha/stdlib/base';
import { flow, map, floor, prop, split, first, isEmpty, compact } from 'lodash/fp';
import { FactorRatingItemType } from '@/interface/fundDetail';
import CardLayout from '@/components/cardLayout';
import { useGetEvaluationReportAbilitySummaryQuery } from '@/infra/api/funds';
import EvaluationDetailTrigger from '@/components/evaluation-detail-trigger';
import DetailModal, { DetailModalProps } from '@/components/detail-modal';
import EmptyContent from '@/components/emptyContent';
import Tabs, { Tab } from '@/components/tabs';
import { factorRatingsOrder, factorRatingsOrderMap, factorRatingsTabOrder } from '../../constants';
import { useGetDefaultRadarOptions } from '../../hooks';
import BaseEcharts from '../../../../components/baseEcharts';
import Rate from '../../../../components/rate';
import style from './index.module.less';
import ComprehensivePerformance from './comprehensivePerformance';
import IndividualStockSelectionAbility from './individualStockSelectionAbility';
import PositionImprovementAbility from './positionImprovementAbility';
import MarketCaptureAbility from './marketCaptureAbility';
import IndustryAbilityEvaluation from './industryAbilityEvaluation';

export default React.memo(() => {
  const { fundId } = useParams();
  const { data: evaluationReportAbilitySummary, isFetching } = useGetEvaluationReportAbilitySummaryQuery(fundId || '', {
    skip: !fundId
  });

  const { factorRatings } = evaluationReportAbilitySummary ?? { factorRatings: [] };
  const sortedFactorRatings = useMemo(
    () =>
      ((mapper) => map((factorCode: string) => mapper[factorCode])(factorRatingsOrder))(
        normalize(factorRatings, 'factorCode')
      ) as FactorRatingItemType[],
    [factorRatings]
  );
  const sortedFactorRatingsTabItems = useMemo(
    () =>
      ((mapper) => map((factorCode: string) => mapper[factorCode])(factorRatingsTabOrder))(
        normalize(factorRatings, 'factorCode')
      ) as FactorRatingItemType[],
    [factorRatings]
  );

  const factorRatingNameMap = useMemo(() => normalize(sortedFactorRatings, 'factorName'), [sortedFactorRatings]);

  const dataSource = useMemo(() => {
    return [
      {
        name: getMessage('theFund'),
        value: map((item: FactorRatingItemType) => item?.fundFactorRating)(sortedFactorRatings)
      },
      {
        name: getMessage('averageOfTheSameType'),
        value: map((item: FactorRatingItemType) => item?.sameTypeFundFactorRating)(sortedFactorRatings)
      }
    ];
  }, [sortedFactorRatings]);

  const options = useGetDefaultRadarOptions({
    radar: {
      triggerEvent: true,
      radius: 50,
      indicator: map((item: FactorRatingItemType) => ({
        name: item?.factorName,
        max: 5
      }))(sortedFactorRatings),
      axisName: {
        formatter: (name) => {
          const ratingNum = flow(prop(`${name}.fundFactorRating`), floor)(factorRatingNameMap) || 0;
          let res = `${name}{question|}\n`;
          const starStr = `{star|}`;
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < ratingNum; index++) {
            res += starStr;
            if (index < ratingNum - 1) res += ' ';
          }
          return res;
        }
      }
    },
    series: [
      {
        z: 2,
        data: [dataSource[0]]
      },
      {
        z: 1,
        data: [dataSource[1]]
      }
    ]
  });

  const modalRef = useRef<{ open: (props: DetailModalProps) => void } | null>(null);
  const handleChartClick = useCallback(
    (param: any) => {
      param?.event?.stop?.();
      const { targetType } = param;
      if (targetType === 'axisName') {
        const name = flow(prop('name'), split('{question|}'), first)(param);
        const factorCode = prop(`${name}.factorCode`)(factorRatingNameMap);
        modalRef?.current?.open({ title: name, content: getMessage(`${factorCode}_detail`) });
      }
    },
    [factorRatingNameMap]
  );

  const isEmptyTabs = useMemo(() => flow(compact, isEmpty)(sortedFactorRatingsTabItems), [sortedFactorRatingsTabItems]);

  return (
    <CardLayout
      title={
        <div className={style.TitleWrap}>
          <div>{getMessage('abilitySummary')}</div>
          <Rate value={prop('totalRating')(evaluationReportAbilitySummary)} />
        </div>
      }
      loading={isFetching}
    >
      <div className={style.AbilitySummaryWrap}>
        <div className={style.AbilityChartWrap}>
          {!isEmpty(compact(sortedFactorRatings)) ? (
            <BaseEcharts events={{ click: handleChartClick }} options={options} height={236} />
          ) : (
            <EmptyContent message={getMessage('noData')} style={{ height: 236 }} />
          )}
        </div>
        <DetailModal ref={modalRef} needTrigger={false} />
        {!isEmptyTabs && (
          <EvaluationDetailTrigger
            text={getMessage('evaluationDetails')}
            initalizeHeight={10}
            collapseText={getMessage('evaluationDetails')}
          >
            <Tabs className={style.Tab} defaultActiveKey={factorRatingsOrderMap.fund_rank_performance}>
              {mapIndexed((item: FactorRatingItemType, idx: number) => (
                <Tab
                  key={item?.factorCode || idx}
                  title={item?.factorName}
                  description={<Rate className={style.Rate} value={item?.fundFactorRating} />}
                >
                  {item?.factorCode === factorRatingsOrderMap.fund_rank_performance && (
                    <ComprehensivePerformance fundCode={fundId ?? ''} />
                  )}
                  {item?.factorCode === factorRatingsOrderMap.fund_rank_selection && (
                    <IndividualStockSelectionAbility fundId={fundId ?? ''} />
                  )}
                  {item?.factorCode === factorRatingsOrderMap.fund_rank_rebalance && (
                    <PositionImprovementAbility fundId={fundId ?? ''} />
                  )}
                  {item?.factorCode === factorRatingsOrderMap.fund_rank_capture && (
                    <MarketCaptureAbility fundId={fundId ?? ''} />
                  )}
                  {item?.factorCode === factorRatingsOrderMap.fund_rank_allocation && (
                    <IndustryAbilityEvaluation fundId={fundId ?? ''} />
                  )}
                </Tab>
              ))(sortedFactorRatingsTabItems)}
            </Tabs>
          </EvaluationDetailTrigger>
        )}
      </div>
    </CardLayout>
  );
});
