import { useMemoizedFn } from 'ahooks';
import TinyColor from '@ctrl/tinycolor';
import { orderBy } from 'lodash/fp';
import { useState } from 'react';
import { forEachIndexed } from '@betalpha/stdlib/base';

// 每个tooltip的宽度
const TooltipNodeWidth = 70;
// 两个tooltip之间的间隔
const TooltipMinSpacing = 6;

// 左边固定宽度
const leftConstantWidth = 70;
// 允许放在最左边的最小位置
const leftMinPosition = leftConstantWidth;

type PositionInfo = {
  left: number;
  right: number;
  width: number;
};

/**
 * 计算tooltip位置的算法，具体逻辑如下：
 *  1.首先获得柱状图对应的柱子的位置和宽度(位置需要减去leftMinPosition，因为柱状图位置是相对于根元素的)
 *  2.根据柱状图的位置两两遍历，计算适合tooltip的位置，通过两个柱状图间的距离和两个tooltip的宽度来判断
 *     1. 如果两个柱状图间的距离大于两个tooltip的宽度，那么宽度比较充裕，这时候是最理想的情况，将tooltip放在柱子中间
 *     2. 如果两个柱状图间的距离小于两个tooltip的宽度，说明此时比较窄，应该根据上一个tooltip的位置，将当前左边tooltip往左偏，右边tooltip往右偏
 *     3. 需要增加左边最小距离和右边最大距离的边界判断，左边最小距离就是上一个tooltip的右边位置 + TooltipMinSpacing，右边最大距离就是maxRightPosition
 * 3.根据计算出来的位置信息，生成最终的tooltip位置信息
 * 4.根据tooltip的位置和柱状图的位置就能够算出三角形的位置
 *
 * 这里为什么要两两遍历：
 *  因为如果一个一个遍历无法确定边界问题，需要多增加一次遍历来调整边界问题的位置
 *  两两遍历是一个渐进的过程，通过两个柱状图的位置来动态调整tooltip的位置，所以不存在边界问题
 */
const calculateTooltipPosition = {
  // 这是最理想的情况，此时tooltip显示在柱状图的正中间
  putTooltipToCorrectPosition(barPositionInfo: PositionInfo) {
    // tooltip节点的实际位置，需要减去左边标题固定宽度和根节点的padding
    const tooltipActualLeftPosition = barPositionInfo.left - leftMinPosition;
    // tooltip节点宽度 - 柱状图宽度 = 他们之间的间距
    const distance = TooltipNodeWidth - barPositionInfo.width;
    // 他们之间的间距 / 2 等于需要往左移的距离
    const gap = distance / 2;
    // 因为要保证tooltip的位置和柱状图的位置尽可能居中，所以需要减去他们之间的宽度之差 / 2
    // 调整后的tooltip的左边的位置
    return tooltipActualLeftPosition - gap;
  },
  adaptor(barPositionInfo: PositionInfo, minLeft: number, maxRight: number) {
    const adaptLeft = this.putTooltipToCorrectPosition(barPositionInfo);
    const adaptRight = adaptLeft + TooltipNodeWidth;
    if (adaptLeft < minLeft)
      return {
        left: minLeft,
        right: minLeft + TooltipNodeWidth,
        width: TooltipNodeWidth
      };
    if (adaptRight > maxRight)
      return {
        left: maxRight - TooltipNodeWidth,
        right: maxRight,
        width: TooltipNodeWidth
      };
    return {
      left: adaptLeft,
      right: adaptRight,
      width: TooltipNodeWidth
    };
  },
  // 处理相邻两个tooltip的位置信息
  generateNearByTwoPointPosition(
    leftBarPositionInfo: PositionInfo,
    rightBarPositionInfo: PositionInfo,
    previousTooltipPositionInfo: PositionInfo | undefined
  ) {
    const maxRightPosition = document.documentElement.clientWidth - leftMinPosition * 2 + leftConstantWidth;
    const leftBarLeftPosition = leftBarPositionInfo.left - leftMinPosition;
    const rightBarRightPosition = rightBarPositionInfo.right - leftMinPosition;
    const distance = rightBarRightPosition - leftBarLeftPosition;
    const twoTooltipWidth = TooltipNodeWidth * 2 + TooltipMinSpacing;
    // 如果两个柱子之间的距离大于两个tooltip的宽度，那么宽度比较充裕，这时候是最理想的情况，将tooltip放在柱子中间
    if (distance > twoTooltipWidth) {
      const leftTooltipAdaptor = this.adaptor(
        leftBarPositionInfo,
        (previousTooltipPositionInfo?.right || 0) + TooltipMinSpacing,
        maxRightPosition
      );
      const rightTooltipAdaptor = this.adaptor(
        rightBarPositionInfo,
        leftTooltipAdaptor.right + TooltipMinSpacing,
        maxRightPosition
      );
      return { leftTooltipAdaptor, rightTooltipAdaptor };
    }
    // 如果两个柱子之间的距离小于两个tooltip的宽度，说明宽度比较窄，这时候需要根据前一个tooltip的位置，以及宽度之差来调整两个tooltip的位置
    const remainWidth = twoTooltipWidth - distance;
    // maxRightPosition - twoTooltipWidth 为两个柱子所能放的最右侧的情况
    // 以及左边柱子的位置 - 两个tooltip的宽度 / 2
    // 取他们中的较小值 （即靠左的一个值）
    let adaptLeftPosition = Math.min(leftBarLeftPosition - remainWidth / 2, maxRightPosition - twoTooltipWidth);
    // 如果当前tooltip左侧坐标超出左侧区域
    if (adaptLeftPosition < 0) {
      // 如果存在前置tooltip（即当前为第二个tooltip，并且已经和第一个tooltip重合（左侧坐标小于0了））
      if (previousTooltipPositionInfo) {
        adaptLeftPosition = previousTooltipPositionInfo.right + TooltipMinSpacing;
      } else {
        adaptLeftPosition = 0;
      }
    }
    // 如果存在前置tooltip（当前为第二个tooltip）
    if (previousTooltipPositionInfo) {
      // 如果前置tooltip的右侧边界已经超过当前tooltip的左侧边界（发生重叠或者间距过小）
      if (previousTooltipPositionInfo.right + TooltipMinSpacing > adaptLeftPosition) {
        // 当前tooltip左侧剩余空间不足容纳前置tooltip + 间距
        if (adaptLeftPosition < TooltipMinSpacing + TooltipNodeWidth) {
          // 将当前tooltip右移，留出空间
          adaptLeftPosition = previousTooltipPositionInfo.right + TooltipMinSpacing;
        } else {
          // 将前置tooltip左移，留出空间
          previousTooltipPositionInfo.right = adaptLeftPosition - TooltipMinSpacing;
          previousTooltipPositionInfo.left = previousTooltipPositionInfo.right - previousTooltipPositionInfo.width;
        }
      }
    }

    const leftTooltipAdaptor = {
      left: adaptLeftPosition,
      width: TooltipNodeWidth,
      right: adaptLeftPosition + TooltipNodeWidth
    };

    // 只要决定了左边的位置，就能够算出右边的位置
    // 右边的距离则是左边的距离加上间距再加上tooltip的宽度
    const adaptRight = leftTooltipAdaptor.right + TooltipMinSpacing + TooltipNodeWidth;
    const rightTooltipAdaptor = {
      left: leftTooltipAdaptor.right + TooltipMinSpacing,
      right: adaptRight > maxRightPosition ? maxRightPosition : adaptRight,
      width: TooltipNodeWidth
    };
    return { leftTooltipAdaptor, rightTooltipAdaptor };
  },
  generate(barPositionInfo: PositionInfo[]) {
    const keepPosition: PositionInfo[] = [];
    forEachIndexed((position: PositionInfo, idx: number) => {
      if (idx >= barPositionInfo.length - 1) return;
      const { leftTooltipAdaptor, rightTooltipAdaptor } = this.generateNearByTwoPointPosition(
        position,
        barPositionInfo[idx + 1],
        keepPosition[idx - 1]
      );
      keepPosition.splice(idx, 1);
      keepPosition.push(leftTooltipAdaptor, rightTooltipAdaptor);
    })(barPositionInfo);
    return keepPosition;
  }
};

const useDrawTriangleNode = (
  tooltipPositionInfo: PositionInfo[] | undefined,
  barPositionInfo: PositionInfo[] | undefined,
  triangleColor: string | undefined
) => {
  if (!barPositionInfo || !tooltipPositionInfo) return null;
  return tooltipPositionInfo.map((it, index) => {
    // 左上角的点
    const topLeftPointX = it.left + 25;
    const topLeftPointY = 0;
    // 右上角的点
    const topRightPointX = it.left + 45;
    const topRightPointY = 0;
    // 底部的点
    const bottomPointX = barPositionInfo[index].right - barPositionInfo[index].width / 2 - leftMinPosition;
    const bottomPointY = 40;
    // 画三角形
    return (
      <svg
        key={it.left}
        style={{ position: 'absolute', left: 0, bottom: -40, width: '100%' }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        height="40"
      >
        <path
          d={`M${bottomPointX} ${bottomPointY} L${topLeftPointX} ${topLeftPointY} L${topRightPointX} ${topRightPointY} Z`}
          fill={triangleColor}
        />
      </svg>
    );
  });
};

export const useCalculatePosition = (markAreaColor: string, svgBackgroundColor: string | undefined) => {
  const [tooltipPositionInfo, setTooltipPositionInfo] = useState<PositionInfo[] | undefined>(undefined);
  const [barPositionInfo, setBarPositionInfo] = useState<PositionInfo[] | undefined>(undefined);
  const updateBarPositionInfo = useMemoizedFn((positionInfo: PositionInfo[]) => {
    const orderedBarPositionInfo = orderBy<PositionInfo>('left', 'asc')(positionInfo);
    setBarPositionInfo(orderedBarPositionInfo);
    setTooltipPositionInfo(calculateTooltipPosition.generate(orderedBarPositionInfo));
  });
  const onChartReady = useMemoizedFn((instance: any) => {
    // 找到三个柱状图的dom节点，并且获取他们的宽度和位置
    const calculateBarPosition = () => {
      const dom = instance.getDom();
      if (dom) {
        const tinyAreaColor = TinyColor(markAreaColor);
        const barInfo: PositionInfo[] = [];
        [...dom.querySelectorAll('path')].forEach((it) => {
          const fillColor = TinyColor(it.getAttribute('fill'));
          if (tinyAreaColor.r === fillColor.r && tinyAreaColor.g === fillColor.g && tinyAreaColor.b === fillColor.b) {
            const itemRect = it.getBoundingClientRect();
            const chartRect = dom.getBoundingClientRect();
            const barLeft = itemRect.left - chartRect.left;
            barInfo.push({
              left: barLeft,
              right: barLeft + itemRect.width,
              width: itemRect.width
            } as PositionInfo);
          }
        });
        updateBarPositionInfo(barInfo);
      }
    };

    const originResize = instance.resize;
    // 劫持resize事件，每次出发resize都重新计算位置
    instance.resize = (...args: any[]) => {
      originResize(...args);
      setTimeout(calculateBarPosition, 300);
    };
  });

  // 画三角形
  const triangleNode = useDrawTriangleNode(tooltipPositionInfo, barPositionInfo, svgBackgroundColor);

  return { onChartReady, tooltipPositionInfo, triangleNode };
};
