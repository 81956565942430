import { getMessage } from '@betalpha/intl';
import { normalize } from '@betalpha/stdlib/base';
import { toFixedNumber } from '@betalpha/stdlib/lib/numberFormatter';
import { EChartsOption } from 'echarts';
import { flow, keys, map, identity, zip, first, values, nth, prop } from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import { useSize } from 'ahooks';
import { FactorValuesParam } from '@/interface/fundDetail';
import { useGetEvaluationReportRiskSummaryQuery } from '@/infra/api/funds';
import TagList from '@/components/tag-list';
import EmptyContent from '@/components/emptyContent';
import BaseEcharts from '../../../../../components/baseEcharts';
import { getProsperity } from '../constants';
import style from '../index.module.less';

// 本组件中的Echarts在屏幕宽度为375下的默认宽度
const ECHARTS_NORMAL_SCREEN_WIDTH = 315;

export default React.memo(({ fundId }: { fundId: string }) => {
  const { data: evaluationReportRiskSummary } = useGetEvaluationReportRiskSummaryQuery(fundId, {
    skip: !fundId
  });

  const { mainFactorRisk } = evaluationReportRiskSummary ?? {};

  const { factorValues, top2FactorCongestion, comprehensiveFactorCongestion } = mainFactorRisk ?? { factorValues: [] };

  const factorValuesMap = useMemo(() => normalize<FactorValuesParam>(factorValues, 'factorCode'), [factorValues]);

  const top2Factors = useMemo(
    () =>
      flow(
        keys,
        map((key: string) => prop(`${key}.factorName`)(factorValuesMap) + getMessage('factor'))
      )(top2FactorCongestion),
    [factorValuesMap, top2FactorCongestion]
  );

  const { factorNames, factorVals } = useMemo(
    () => ({
      factorNames: map((item: FactorValuesParam) => item?.factorName)(factorValues),
      factorVals: map((item: FactorValuesParam) => item?.factorValue)(factorValues)
    }),
    [factorValues]
  );

  const chartWrapRef = useRef(null);
  const { width: chartWrapWidth } = useSize(chartWrapRef) ?? { width: 0 };
  const options = useMemo<EChartsOption>(
    () => ({
      grid: { top: 23, left: 24, bottom: 45 },
      legend: { show: false },
      yAxis: {
        axisTick: { show: false },
        axisLine: { show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#D2D5D9',
            type: 'dotted'
          }
        },
        min: 0,
        max: 100,
        axisLabel: {
          formatter: identity,
          fontSize: 10,
          color: '#666666',
          fontWeight: 400
        }
      },
      xAxis: {
        axisLabel: {
          fontSize: 10,
          color: '#666666',
          fontWeight: 400,
          overflow: 'break',
          width: (30 / ECHARTS_NORMAL_SCREEN_WIDTH) * chartWrapWidth,
          hideOverlap: false,
          interval: 0
        }
      },
      series: {
        type: 'bar',
        barWidth: (18 / ECHARTS_NORMAL_SCREEN_WIDTH) * chartWrapWidth,
        silent: true,
        itemStyle: {
          color: '#CFB47F'
        },
        label: {
          show: false,
          formatter: (params) => {
            const value = prop('value.0')(params);
            return toFixedNumber(2)(value);
          }
        },
        data: zip(factorNames)(factorVals) as [string, number][]
      }
    }),
    [chartWrapWidth, factorNames, factorVals]
  );
  return (
    <div className={style.RecentStatusOfMainFactorsyWrap}>
      {mainFactorRisk ? (
        <>
          <div className={style.SubTitleWrap}>
            <TagList title={getMessage('top2CurrentStyleFactors')} tags={top2Factors} />
          </div>
          <div className={style.Text}>
            {getMessage('mainFactorsSummaryForMobile', {
              firstFactor: flow(keys, first, (key) => prop(`${key}.factorName`)(factorValuesMap))(top2FactorCongestion),
              prosperity: getProsperity(flow(values, first)(top2FactorCongestion) as string),
              secondFactor: flow(keys, nth(1), (key) => prop(`${key}.factorName`)(factorValuesMap))(
                top2FactorCongestion
              ),
              crowdDegree: getProsperity(flow(values, nth(1))(top2FactorCongestion) as string),
              fundCrowdDegree: getProsperity(comprehensiveFactorCongestion)
            })}
          </div>
          <div className={style.ChartWrap} ref={chartWrapRef}>
            <BaseEcharts options={options} height={224} />
          </div>
        </>
      ) : (
        <EmptyContent message={getMessage('noData')} />
      )}
    </div>
  );
});
