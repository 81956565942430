import { getMessage } from '@betalpha/intl';
import { flow, prop, toArray } from 'lodash/fp';
import React from 'react';
import { useGetBarChartAndSummaryData } from '@/views/fund-evaluation-detail/hooks';
import { dispersionDegreeMap, CompareLevel, compareLevel } from '@/views/fund-evaluation-detail/constants';
import { formatPercentage } from '@/util/numberFormatter';
import { useGetIndustryAbilityEvaluationQuery } from '@/infra/api/funds';
import TagList from '@/components/tag-list';
import Collapse from '@/components/collapse';
import BaseEcharts from '../../../../../components/baseEcharts';
import SimilarRanking from '../../similarRanking';
import style from '../index.module.less';

export default React.memo<{ fundId: string }>(({ fundId }) => {
  useGetIndustryAbilityEvaluationQuery(fundId, { skip: !fundId });
  const {
    sameTypeRank,
    top3IndustryInEquity,
    top3IndustryWeightRating,
    industry2Name,
    industryAllocationCompareWithSameType,
    brinsonAllocationContribute,
    factorModelAllocationContribute,
    options
  } = useGetBarChartAndSummaryData(fundId);

  return (
    <div className={style.IndustryAbilityEvaluationWrap}>
      <SimilarRanking rank={sameTypeRank?.rank} count={sameTypeRank?.count} />
      <div className={style.top3IndustryInEquityWrap}>
        <div className={style.Left}>
          <div className={style.Label}>{getMessage('top3IndustryInEquity')}</div>
          <div className={style.ProgressWrap}>
            <div className={style.Progress}>
              <div style={{ width: formatPercentage(top3IndustryInEquity) }} />
            </div>
            <div className={style.ProgressValue}>{formatPercentage(top3IndustryInEquity)}</div>
          </div>
        </div>
        <div className={style.Right}>
          <div className={style.Label}>{getMessage('top3IndustryWeightRating')}</div>
          <div className={style.Level}>{flow(prop(top3IndustryWeightRating), getMessage)(dispersionDegreeMap)}</div>
        </div>
      </div>
      <TagList title={getMessage('top3HoldingPositionsIndustries')} tags={toArray(industry2Name)} />
      <div className={style.Summary}>
        <Collapse
          title={getMessage('industryAllocationCompareWithSameType', {
            industryAllocationCompareWithSameType: flow(
              prop(industryAllocationCompareWithSameType),
              getMessage
            )(CompareLevel)
          })}
        >
          {getMessage('industryAbilityEvaluationSummaryForMobile', {
            fundBrinsonContribute: formatPercentage(brinsonAllocationContribute?.fundContribute),
            brinsonSameTypeContribute: compareLevel(
              brinsonAllocationContribute?.fundContribute,
              brinsonAllocationContribute?.sameTypeContribute
            ),
            fundFactorContribute: formatPercentage(factorModelAllocationContribute?.fundContribute),
            factorSameTypeContribute: compareLevel(
              factorModelAllocationContribute?.fundContribute,
              factorModelAllocationContribute?.sameTypeContribute
            )
          })}
        </Collapse>
      </div>
      <BaseEcharts options={options} height={270} />
    </div>
  );
});
