import { getMessage } from '@betalpha/intl';
import { useMemo } from 'react';
import { size } from 'lodash/fp';
import { FundPerformanceBody } from '@/interface/fundDetail';
import { CHART_COLOR } from '@/views/fund-evaluation-detail/constants';
import { ChartProps } from '@/base-components/charts/yieldTrendChart/type';

export const useGetPerformanceTrend = (data: FundPerformanceBody | undefined) => {
  const { performanceTrend } = data || {};

  const { fiveStarFundCumulativeReturn, oneStarFundCumulativeReturn, dates, benchmarkCumulativeReturn } =
    performanceTrend || {};

  const series = useMemo(() => {
    return [
      {
        name: getMessage('fiveStarFund'),
        color: CHART_COLOR[0],
        dates,
        returns: fiveStarFundCumulativeReturn
      },
      {
        name: getMessage('oneStarFund'),
        color: CHART_COLOR[1],
        dates,
        returns: oneStarFundCumulativeReturn
      },
      {
        name: getMessage('twoSetsOfHedgingGains'),
        color: CHART_COLOR[2],
        dates,
        returns: benchmarkCumulativeReturn
      }
    ];
  }, [benchmarkCumulativeReturn, dates, fiveStarFundCumulativeReturn, oneStarFundCumulativeReturn]);

  const chartOptions = useMemo<Partial<ChartProps>>(
    () => ({
      legendOptions: {
        left: 'center',
        bottom: 36,
        top: 'auto',
        itemGap: 30,
        padding: 0,
        textStyle: { color: '#666666', fontSize: 14 }
      },
      gridOptions: { left: 0, top: 30, bottom: 65, right: 4 },
      xAxisOptions: {
        boundaryGap: false,
        axisLabel: {
          color: '#666666',
          hideOverlap: false,
          interval: Math.floor(size(dates) / 2),
          fontFamily: 'DINAlternate'
        },
        axisTick: { show: true },
        axisLine: { onZero: true }
      },
      yAxisOptions: {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'dotted'
          }
        },
        axisLabel: {
          fontWeight: 'bold',
          color: '#222222',
          fontFamily: 'DINAlternate'
        }
      }
    }),
    [dates]
  );

  return useMemo(
    () => ({
      series,
      dates: dates || [],
      chartOptions
    }),
    [chartOptions, dates, series]
  );
};
