import React from 'react';
import { Routes as InnerRoutes, Outlet, Route } from '@betalpha/router';

/**
 * register routes start
 */
import NotFoundPage from '@/components/not-found';
import FundEvaluationHome from '@/views/fund-evaluation-home';
import FundEvaluationDetail from '@/views/fund-evaluation-detail';
/**
 * register routes end
 */

const Routes = () => {
  /**
   * 兼容旧版路由:
   *  请求路径示例：api4.betalpha.com/fund-evaluation
   *  请求路径示例：api4.betalpha.com/fund-evaluation/00022_OF
   *  */
  if (window.location.pathname.includes('/fund-evaluation')) {
    return (
      <InnerRoutes>
        <Route path="/fund-evaluation" element={<Outlet />}>
          <Route index element={<FundEvaluationHome />} />
          <Route path=":fundId" element={<FundEvaluationDetail />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </InnerRoutes>
    );
  }

  /**
   * 域名请求:
   *  请求路径示例：fund.betalpha.com
   *  请求路径示例：fund.betalpha.com/00022_OF
   *  */
  return (
    <InnerRoutes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<FundEvaluationHome />} />
        <Route path=":fundId" element={<FundEvaluationDetail />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </InnerRoutes>
  );
};

export default Routes;
