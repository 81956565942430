import React, { useMemo, useRef, useState } from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { mapIndexed } from '@betalpha/stdlib/base';
import cn from 'classnames';
import CardLayout from '@/components/cardLayout';
import { EvaluationReportRiskSummaryBody } from '@/interface/fundDetail';
import { useGetEvaluationReportRiskSummaryQuery } from '@/infra/api/funds';
import { formatPercentage } from '@/util/numberFormatter';
import SplitProgressBar from '@/components/split-progress-bar';
import DetailModal, { DetailModalProps } from '@/components/detail-modal';
import style from './index.module.less';
import StyleDynamicAlert from './styleDynamicAlert';
import RecentSituationOfHeavyWarehouseIndustry from './recentSituationOfHeavyWarehouseIndustry';
import RecentStatusOfMainFactors from './recentStatusOfMainFactors';

type GetType<T, key extends keyof T> = Omit<T, key>[keyof Omit<T, key>];

export default React.memo(() => {
  const { fundId } = useParams();
  const { data: evaluationReportRiskSummary, isFetching } = useGetEvaluationReportRiskSummaryQuery(fundId || '', {
    skip: !fundId
  });

  const { totalRisk, styleRisk, industryRisk, mainFactorRisk } = evaluationReportRiskSummary || {};

  type TabItem = GetType<EvaluationReportRiskSummaryBody, 'totalRisk'> & {
    title: string;
  };
  const tabItems = useMemo<Partial<TabItem>[]>(
    () => [
      { ...(styleRisk || {}), title: getMessage('styleDynamicAlert') },
      { ...(industryRisk || {}), title: getMessage('recentSituationOfHeavyWarehouseIndustry') },
      { ...(mainFactorRisk || {}), title: getMessage('recentStatusOfMainFactors') }
    ],
    [industryRisk, mainFactorRisk, styleRisk]
  );

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const modalRef = useRef<{ open: (props: DetailModalProps) => void } | null>(null);
  return (
    <CardLayout
      title={
        <div className={style.TitleWrap}>
          <span>{getMessage('riskProbe')}</span>
          <DetailModal
            ref={modalRef}
            title={getMessage('riskProbe')}
            content={getMessage('riskProbeDetail')}
            triggerClassName={style.Question}
          />
          <div className={style.TotalRisk}>{getMessage(totalRisk ? `${totalRisk}_RISK_TITLE` : 'noData')}</div>
        </div>
      }
      loading={isFetching}
    >
      <div className={style.RiskSummaryWrap}>
        <div className={style.RiskTabs}>
          {mapIndexed((item: any, tabIdx: number) => (
            <div
              className={cn(style.RiskTabItem, activeTabIndex === tabIdx && style.ActiveTabItem)}
              key={item?.title}
              onClick={(e) => {
                e.stopPropagation();
                setActiveTabIndex(tabIdx);
              }}
            >
              <div className={style.Title}>{item?.title}</div>
              <div className={cn(style.RiskLevel, style[item?.riskLevel])}>
                {(item?.riskLevel && getMessage(`${item?.riskLevel}_RISK`)) || getMessage('noData')}
              </div>
              <SplitProgressBar value={item?.riskScore} />
            </div>
          ))(tabItems)}
        </div>
        <div className={style.RiskContentWrap}>
          <div className={style.TabContentArrow} style={{ left: formatPercentage((activeTabIndex * 2 + 1) / 6) }} />
          {activeTabIndex === 0 && <StyleDynamicAlert fundId={fundId ?? ''} />}
          {activeTabIndex === 1 && <RecentSituationOfHeavyWarehouseIndustry fundId={fundId ?? ''} />}
          {activeTabIndex === 2 && <RecentStatusOfMainFactors fundId={fundId ?? ''} />}
        </div>
      </div>
    </CardLayout>
  );
});
