import React, { useMemo, FC } from 'react';
import { getMessage } from '@betalpha/intl';
import { isEmpty, map } from 'lodash/fp';

import { percentage } from '@betalpha/stdlib/lib/numberFormatter';

import { HotFundsType } from '@/interface/fundDetail';
import YieldTrendChart from '@/base-components/charts/yieldTrendChart';
import { ChartProps } from '@/base-components/charts/yieldTrendChart/type';
import Rate from '@/components/rate';
import EmptyContent from '@/components/emptyContent';
import ColorNumber from '../../../../components/colorNumber';
import style from './index.module.less';

interface FiveStarChartProps {
  value: HotFundsType;
}
const FiveStarChart = React.memo<FiveStarChartProps>(({ value }) => {
  const { returnTrend } = value || {};
  const { dailyReturn, dates } = returnTrend || {};

  const finalChartOptions = useMemo<Partial<ChartProps>>(
    () => ({
      legendOptions: {
        show: false
      },
      tooltipOptions: {
        show: false
      },
      gridOptions: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        containLabel: false
      },
      yAxisOptions: {
        show: false,
        splitNumber: null
      },
      xAxisOptions: {
        show: true
      }
    }),
    []
  );
  const series = useMemo(
    () => [
      {
        name: getMessage('theFund'),
        color: '#CFB47F',
        dates,
        dailyReturns: dailyReturn
      }
    ],
    [dailyReturn, dates]
  );

  return (
    <YieldTrendChart dates={dates} series={series} showDataZoom={false} height={28} onZero {...finalChartOptions} />
  );
});

type HotFundProps = {
  data?: HotFundsType[];
  onClick: (id: string) => void;
};

const HotFund: FC<HotFundProps> = ({ data, onClick }) => {
  if (isEmpty(data)) return <EmptyContent message={getMessage('noData')} />;

  return (
    <div className={style.ListWrap}>
      {map((item: HotFundsType) => (
        <div
          key={item.fundId}
          onClick={(e) => {
            e.stopPropagation();
            onClick(item.fundId);
          }}
        >
          <div className={style.ChartWrap}>
            <FiveStarChart value={item} />
          </div>
          <div className={style.YieldWrap}>
            <ColorNumber value={item.cumulativeReturn} formatter={percentage} className={style.ColorNumber} />
            <div className={style.RecentThreeYearYield}>{getMessage('recentThreeYearYield')}</div>
          </div>
          <div className={style.FundInfoWrap}>
            <div className={style.FundName}>{item.fundName}</div>
            <Rate value={item.fundRating} />
          </div>
        </div>
      ))(data)}
    </div>
  );
};

export default HotFund;
