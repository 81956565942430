import { getMessage } from '@betalpha/intl';
import { prop, flow } from 'lodash/fp';
import React from 'react';
import { useGetMarketCapabilityDetailQuery, useGetEvaluationReportInfoQuery } from '@/infra/api/funds';
import Collapse from '@/components/collapse';
import style from '../index.module.less';
import SimilarRanking from '../../similarRanking';
import { MarketCapabilityLevel, AbilityLevel } from './contants';

import MarketAbilityChart from './marketAbilityChart';

export default React.memo(({ fundId }: { fundId: string }) => {
  const { data: marketCapabilityDetail } = useGetMarketCapabilityDetailQuery(fundId, { skip: !fundId });
  const { data: fundEvaluationReportInfo } = useGetEvaluationReportInfoQuery(fundId, { skip: !fundId });

  const {
    marketCapabilitySameTypeRank,
    sameTypeFundCount,
    marketCapabilityLevel,
    defensiveAbilityLevel,
    offensiveAbilityLevel,
    risingMarketCaptureRank,
    fallingMarketCaptureRank
  } = marketCapabilityDetail ?? { marketCapabilityLevel: '', offensiveAbilityLevel: '', defensiveAbilityLevel: '' };

  return (
    <div className={style.MarketCaptureAbilityWrap}>
      <SimilarRanking rank={marketCapabilitySameTypeRank} count={sameTypeFundCount} className={style.SimilarRank} />
      <div className={style.Text}>
        <Collapse
          title={getMessage('marketCapabilityLevel', {
            marketCapabilityLevel: flow(prop(marketCapabilityLevel), getMessage)(MarketCapabilityLevel),
            offensiveAbilityLevel: flow(prop(offensiveAbilityLevel), getMessage)(AbilityLevel),
            defensiveAbilityLevel: flow(prop(defensiveAbilityLevel), getMessage)(AbilityLevel)
          })}
        >
          {getMessage('marketCaptureAbilitySummaryForMobile', {
            fundName: getMessage('theFund'),
            risingMarketCaptureRank: `${risingMarketCaptureRank}/${sameTypeFundCount}`,
            fallingMarketCaptureRank: `${fallingMarketCaptureRank}/${sameTypeFundCount}`
          })}
        </Collapse>
      </div>
      <MarketAbilityChart
        name={getMessage('marketRiseStageRank')}
        type="RISE"
        markAreaColor="#F2E5DC"
        fundEvaluationReportInfo={fundEvaluationReportInfo}
        marketCapabilityDetail={marketCapabilityDetail}
        className={style.MarketAbilityRiseChart}
      />
      <MarketAbilityChart
        name={getMessage('marketFallStageRank')}
        type="FALL"
        markAreaColor="#D5EDDF"
        fundEvaluationReportInfo={fundEvaluationReportInfo}
        marketCapabilityDetail={marketCapabilityDetail}
        className={style.MarketAbilityFallChart}
      />
    </div>
  );
});
