import React, { useMemo } from 'react';

import { compact, flow, identity, isEmpty, join, map, orderBy, prop } from 'lodash/fp';
import { EChartsOption } from 'echarts';
import { mapIndexed } from '@betalpha/stdlib/base';
import { getHTMLMessage, getMessage } from '@betalpha/intl';
import { ManagerEvaluationBody } from '@/interface/fundDetail';
import EmptyContent from '@/components/emptyContent';
import BaseEcharts from '../../../../components/baseEcharts';
import style from './index.module.less';

type ManagerExchangeDataType = { date: string; from: string[]; to: string }[];

export default React.memo(({ managerEvaluation }: { managerEvaluation?: ManagerEvaluationBody }) => {
  const { fundScoreTrend, fundManagerRecords } = managerEvaluation || {};
  const managerExchangeData: ManagerExchangeDataType = useMemo(() => {
    return flow(
      mapIndexed((managers: ManagerEvaluationBody['fundManagerRecords'][string], key: string) => {
        return { date: key, managers };
      }),
      orderBy((item: Record<string, any>) => item?.date)('asc'),
      mapIndexed((v: Record<string, any>, idx: number, currentArr: Record<string, any>) => {
        if (idx > 0) {
          return {
            date: v.date,
            from: currentArr[idx - 1]?.managers,
            to: v.managers
          };
        }
        return null;
      }),
      compact
    )(fundManagerRecords);
  }, [fundManagerRecords]);

  const lineChartOptions = useMemo<EChartsOption>(
    () => ({
      grid: { left: 32, top: 8, bottom: 44, right: 20 },
      xAxis: {
        axisTick: { show: true, alignWithLabel: true },
        axisLabel: {
          fontWeight: 400,
          fontSize: 9
        }
      },
      yAxis: {
        max: 100,
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'dotted'
          }
        },
        axisLabel: {
          fontWeight: 400,
          fontSize: 10,
          formatter: identity
        }
      },
      series: {
        type: 'line',
        data: map((item: Record<string, any>) => [prop('date')(item), prop('score')(item)])(fundScoreTrend),
        markLine: {
          symbol: 'none',
          label: { show: false },
          silent: true,
          lineStyle: {
            color: 'rgba(207,180,127,0.3)',
            width: 16,
            type: 'solid'
          },
          data: map((item: Record<string, any>) => ({
            xAxis: prop('date')(item)
          }))(managerExchangeData)
        }
      }
    }),
    [fundScoreTrend, managerExchangeData]
  );
  return (
    <div className={style.FundHistoryInfoWrap}>
      <div className={style.Title}>{getMessage('fundManagerChangeHistory')}</div>
      <div className={style.ChartWrap}>
        {!isEmpty(fundScoreTrend) ? (
          <BaseEcharts options={lineChartOptions} height={180} />
        ) : (
          <EmptyContent message={getMessage('noData')} />
        )}
      </div>
      {!isEmpty(managerExchangeData) && (
        <div className={style.HistoryInfo}>
          {map((item: ManagerExchangeDataType[number]) => (
            <div key={item?.date}>
              {getHTMLMessage('fundManagerChangeInfoHTML', {
                date: prop('date')(item),
                from: flow(prop('from'), join('、'))(item),
                to: flow(prop('to'), join('、'))(item)
              })}
            </div>
          ))(managerExchangeData)}
        </div>
      )}
    </div>
  );
});
