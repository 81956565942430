import { Table } from 'antd';
import { flow, prop } from 'lodash/fp';
import React from 'react';
import { getMessage } from '@betalpha/intl';
import { formatPercentage } from '@/util/numberFormatter';
import { useGetEvaluationReportInfoQuery } from '@/infra/api/funds';
import Collapse from '@/components/collapse';
import { getColumns, RatePerformanceMap, riskLevelMap, riskLevelMap1 } from '../../../constants';
import { useGetLineChartData, useComprehenPerformance } from '../../../hooks';
import PerformanceTrendChart from '../../performanceTrend/performanceTrendChart';
import style from '../index.module.less';
import SimilarRanking from '../../similarRanking';

interface ComprehensivePerformanceProps {
  fundCode: string;
}
export default React.memo<ComprehensivePerformanceProps>(({ fundCode }) => {
  const columns = getColumns();

  // useGetComprehensivePerformanceQuery(fundCode);

  const { data: fundEvaluationReportInfo } = useGetEvaluationReportInfoQuery(fundCode, { skip: !fundCode });
  const { fundName } = useGetLineChartData(fundEvaluationReportInfo);

  const { dataSource, summary } = useComprehenPerformance(fundCode);

  return (
    <div className={style.ComprehensivePerformance}>
      <SimilarRanking rank={summary?.comprehensivePerformanceRank} count={dataSource[0]?.sameTypeFactorRank?.count} />
      <div className={style.Summary}>
        <Collapse
          title={getMessage('pastThreeYearPerformance', {
            fundName,
            performanceRating: flow(
              prop(summary?.performanceRating ?? ''),
              (msgKey: string) => msgKey && getMessage(msgKey)
            )(RatePerformanceMap)
          })}
        >
          {getMessage('comprehensivePerformanceSummaryForMobile', {
            riskLevel: flow(
              prop(summary?.riskLevel ?? ''),
              (msgKey: string) => msgKey && getMessage(msgKey)
            )(riskLevelMap),
            riskLevelPerformance: flow(
              prop(summary?.riskLevel ?? ''),
              (msgKey: string) => msgKey && getMessage(msgKey)
            )(riskLevelMap1),
            avgReturn: formatPercentage(summary?.avgReturn),
            winRate: formatPercentage(summary?.winRate),
            holdingExperience: flow(
              prop(summary?.holdingExperience ?? ''),
              (msgKey: string) => msgKey && getMessage(msgKey)
            )(riskLevelMap1)
          })}
        </Collapse>
      </div>
      <PerformanceTrendChart fundEvaluationReportInfo={fundEvaluationReportInfo} showDate />
      <div className={style.TableWrap}>
        <div className={style.Divider} />
        <Table
          columns={columns as any}
          dataSource={dataSource}
          rowKey="name"
          pagination={false}
          bordered={false}
          className={style.PerformanceTable}
        />
      </div>
    </div>
  );
});
