import React from 'react';
import { isNumber, map, startsWith } from 'lodash/fp';
import { formatPercentage } from '@/util/numberFormatter';

import style from './index.module.less';

const DEFAULT_CHART_COLOR = ['#5e79a4', '#cfb47f', '#d78171'];
interface SplitProgressBarProps {
  value: number;
  chartColor?: string[];
}
const SplitProgressBar = React.memo<SplitProgressBarProps>(({ value, chartColor = DEFAULT_CHART_COLOR }) => {
  let clipPathVal = formatPercentage(1 - (value ?? 0) / 100);
  if (startsWith('70')(clipPathVal) || startsWith('30')(clipPathVal)) {
    clipPathVal = `calc(${clipPathVal} - 1px)`;
  }

  return (
    <div className={style.ProgressBar}>
      <div
        className={style.Arrow}
        style={{ left: value < 0 || !isNumber(value) ? '-9999%' : formatPercentage((value ?? 0) / 100) }}
      />
      <div className={style.Container}>{map((color: string) => <div key={color} />)(chartColor)}</div>
      <div className={style.Progress} style={{ clipPath: `inset(0 ${clipPathVal} 0 0)` }}>
        {map((color: string) => <div key={color} style={{ backgroundColor: color }} />)(chartColor)}
      </div>
    </div>
  );
});

export default SplitProgressBar;
