import React from 'react';
import cn from 'classnames';
import { getMessage } from '@betalpha/intl';
import { formatNil } from '@/util/numberFormatter';
import style from './index.module.less';

type SimilarRankProps = {
  rank?: number;
  count?: number;
  className?: string;
};

export default React.memo(({ rank, count, className }: SimilarRankProps) => {
  return (
    <div className={cn(style.SimilarRankContainer, className)}>
      <div className={style.SimilarRankName}>{getMessage('similarRankings')}：</div>
      <div className={style.SimilarCount}>
        <span className={style.Rank}>{formatNil(rank)}</span>/{formatNil(count)}
      </div>
    </div>
  );
});
